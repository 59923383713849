import { getDiscounts, getDiscount } from "../../config/config";
import { toast } from "react-toastify";
import { LOGOUT } from "./AuthActions";

export const GET_DISCOUNTS_REQUEST = "GET_DISCOUNTS_REQUEST";
export const GET_DISCOUNTS_SUCCESS = "GET_DISCOUNTS_SUCCESS";
export const GET_DISCOUNTS_ERROR = "GET_DISCOUNTS_ERROR";
export const GET_DISCOUNT_REQUEST = "GET_DISCOUNTS_REQUEST";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_ERROR = "GET_DISCOUNT_ERROR";
export const CLEAR_ALL_DISCOUNTS_DATA = "CLEAR_ALL_DISCOUNTS_DATA";

export const actions = {
  getDiscounts: (isUserLoggedIn, restaurantId) => async (dispatch) => {
    dispatch({ type: GET_DISCOUNTS_REQUEST });
    await getDiscounts(isUserLoggedIn, restaurantId).then((res) => {
      if (res?.status === 200 || res?.status === 501) {
        dispatch({
          type: GET_DISCOUNTS_SUCCESS,
          payload: {
            discounts: res?.data?.userDiscounts,
          },
        });
      } else {
        if (res.status === 401) {
          dispatch({ type: LOGOUT })
        }
        toast.error(res?.data?.message || "Get Discounts error");
        dispatch({
          type: GET_DISCOUNTS_ERROR,
        });
      }
    });
  },
  getDiscount:
    (isUserLoggedIn, restaurantId, discountId) => async (dispatch) => {
      dispatch({ type: GET_DISCOUNT_REQUEST });
      await getDiscount(isUserLoggedIn, restaurantId, discountId).then(
        (res) => {
          if (res?.status === 200) {
            dispatch({
              type: GET_DISCOUNT_SUCCESS,
              payload: {
                discount: res?.data?.discount,
              },
            });
          } else {
            if (res.status === 401) {
              dispatch({ type: LOGOUT })
            }
            toast.error(res?.data?.message || "Get Discount error");
            dispatch({
              type: GET_DISCOUNT_ERROR,
            });
          }
        }
      );
    },
  resetDiscounts: () => (dispatch) => {
    dispatch({
      type:
        CLEAR_ALL_DISCOUNTS_DATA
    });
  },
};
