import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  SEND_ORDER_REQUEST,
  SEND_ORDER_SUCCESS,
  SEND_ORDER_ERROR,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  RESET_ORDER,
  SET_ORDERS_SELECTED_GROUP,
  CLEAR_SEND_ORDER_LOADING,
  CLEAR_ALL_ORDERS_DATA
} from "../actions/OrdersActions";

const initialState = {
  getOrdersLoading: false,
  getOrderLoading: false,
  sendOrderLoading: false,
  cancelOrderLoading: false,
  orders: [],
  ordersRestaurant: null,
  order: null,
  ordersSelectedGroup: "current"
};

const getOrdersAfterOrderCanceled = (orders, canceledOrderId) => {
  let finalOrdersArray = [];
  orders?.forEach((order) => {
    if (order?.uuid === canceledOrderId) {
      finalOrdersArray.push({
        ...order,
        statusKey: "canceled",
      });
    } else {
      finalOrdersArray.push(order);
    }
  });
  return finalOrdersArray;
};

function orders(state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return Object.assign({}, state, {
        getOrdersLoading: action?.payload?.ruuid !== state.ordersRestaurant ? true : false,
      });
    case GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        getOrdersLoading: false,
        orders: action?.payload?.data,
        ordersRestaurant: action?.payload?.ruuid
      });
    case GET_ORDERS_ERROR:
      return Object.assign({}, state, {
        getOrdersLoading: false,
        orders: [],
        ordersRestaurant: action?.payload?.ruuid
      });
    case GET_ORDER_REQUEST:
      return Object.assign({}, state, {
        getOrderLoading: true,
      });
    case GET_ORDER_SUCCESS:
      return Object.assign({}, state, {
        getOrderLoading: false,
        order: action?.payload?.data,
      });
    case GET_ORDER_ERROR:
      return Object.assign({}, state, {
        getOrderLoading: false,
        order: null,
      });
    case SEND_ORDER_REQUEST:
      return Object.assign({}, state, {
        sendOrderLoading: true,
      });
    case SEND_ORDER_SUCCESS:
      return Object.assign({}, state, {
        sendOrderLoading: false,
      });
    case SEND_ORDER_ERROR:
      return Object.assign({}, state, {
        sendOrderLoading: false,
      });
    case CANCEL_ORDER_REQUEST:
      return Object.assign({}, state, {
        cancelOrderLoading: true,
      });
    case CANCEL_ORDER_SUCCESS:
      return Object.assign({}, state, {
        cancelOrderLoading: false,
        orders: getOrdersAfterOrderCanceled(
          state?.orders,
          action?.payload?.orderId
        ),
      });
    case CANCEL_ORDER_ERROR:
      return Object.assign({}, state, {
        cancelOrderLoading: false,
      });
    case RESET_ORDER:
      return Object.assign({}, state, {
        order: null,
      });
    case SET_ORDERS_SELECTED_GROUP:
      return Object.assign({}, state, {
        ordersSelectedGroup: action?.payload?.value
      })
    case CLEAR_SEND_ORDER_LOADING:
      return Object.assign({}, state, {
        sendOrderLoading: false
      })
    case CLEAR_ALL_ORDERS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default orders;
