import { toast } from "react-toastify";
import { getGallery } from "../../config/config";
import appSource from "../reducers/AppSourceReducer";

export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_ERROR = "GET_GALLERY_ERROR";

export const RESET_GALLERY = "RESET_GALLERY";

export const CLEAR_ALL_GALLERY_DATA = "CLEAR_ALL_GALLERY_DATA";

export const actions = {
  getGallery: (restaurantId, onError, appSource) => async (dispatch) => {
    dispatch({ type: GET_GALLERY_REQUEST });
    try {
      const response = await getGallery(restaurantId);
      if (response.status === 200) {
        dispatch({
          type: GET_GALLERY_SUCCESS,
          payload: {
            layout: response?.data?.gallery?.layout,
            gallery: response?.data?.gallery?.categories,
            appSource: appSource,
          },
        });
      } else {
        dispatch({
          type: GET_GALLERY_ERROR,
        });
        onError(response?.data?.message || "Get Gallery error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: GET_GALLERY_ERROR,
      });
      onError(error?.response?.data?.message || "Get Gallery error"); // call the error callback
    }
  },
  resetGallery: () => (dispatch) => {
    dispatch({ type: RESET_GALLERY, appSource: appSource });
  },
  clearAllGalleryData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_GALLERY_DATA
    })
  }
};
