import { getMe, updateProfile, getAllergens } from "../../config/config";
import { toast } from "react-toastify";
import { LOGOUT } from './AuthActions';

export const GET_ME_REQUEST = "GET_ME_REQUEST";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_ERROR = "GET_ME_ERROR";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const RESET_USER = "RESET_USER";
export const GET_ALLERGENS_REQUEST = "GET_ALLERGENS_REQUEST";
export const GET_ALLERGENS_SUCCESS = "GET_ALLERGENS_SUCCESS";
export const GET_ALLERGENS_ERROR = "GET_ALLERGENS_ERROR";

export const actions = {
  //get me
  getMe: () => async (dispatch) => {
    dispatch({ type: GET_ME_REQUEST });
    try {
      const response = await getMe();
      if (response.status === 200) {
        dispatch({
          type: GET_ME_SUCCESS,
          payload: {
            profile: response?.data?.user,
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: GET_ME_ERROR,
        });
        toast.error(response?.data?.message || "Get Me error");
      }
    } catch (error) {
      dispatch({
        type: GET_ME_ERROR,
      });
      toast.error("Get Me error");
    }
  },
  //update profile
  updateProfile: (data, onSuccess) => async (dispatch) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
      const response = await updateProfile(data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: UPDATE_PROFILE_ERROR,
        });
        toast.error(response?.data?.message ? response?.data?.message : typeof (response?.data) === "string" ? response?.data : "Update Profile error");
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_ERROR,
      });
      toast.error("Update Profile error");
    }
  },

  resetUser: () => (dispatch) => {
    dispatch({
      type: RESET_USER
    })
  },

  //get allergens
  getAllergens: () => async (dispatch) => {
    dispatch({ type: GET_ALLERGENS_REQUEST });
    try {
      const response = await getAllergens();
      if (response.status === 200) {
        dispatch({
          type: GET_ALLERGENS_SUCCESS,
          payload: {
            allergens: response?.data?.allergens,
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: GET_ALLERGENS_ERROR
        });
        toast.error(response?.data?.message || "Get Allergens error");
      }
    } catch (error) {
      dispatch({
        type: GET_ALLERGENS_ERROR,
      });
      toast.error("Get Allergens error");
    }
  },
};
