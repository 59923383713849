import { toast } from "react-toastify";
import { getEvents } from "../../config/config";

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const CHANGE_EVENTS_VIEW = "CHANGE_EVENTS_VIEW";

export const CLEAR_ALL_EVENTS_DATA = "CLEAR_ALL_EVENTS_DATA";

export const actions = {
  getEvents: (restaurantId, appSource) => async (dispatch) => {
    dispatch({ type: GET_EVENTS_REQUEST });
    await getEvents(restaurantId).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_EVENTS_SUCCESS,
          payload: {
            events: res?.data?.events,
            appSource: appSource,
          },
        });
      } else {
        toast.error(res?.data?.message || "Get Events error");
        dispatch({
          type: GET_EVENTS_ERROR,
          appSource: appSource,
        });
      }
    });
  },
  changeEventsView: (eView, appSource) => (dispatch) => {
    dispatch({
      type: CHANGE_EVENTS_VIEW,
      payload: {
        eView: eView,
        appSource: appSource,
      },
    });
  },
  clearAllEventsData: (appSource) => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_EVENTS_DATA,
      payload: {
        appSource: appSource,
      },
    });
  },
};
