import {
  getFeedbackTemplate,
  addFeedback,
  addFeedbackQr,
} from "../../config/config";
import { LOGOUT } from './AuthActions';

export const GET_FEEDBACK_TEMPLATE_REQUEST = "GET_FEEDBACK_TEMPLATE";
export const GET_FEEDBACK_TEMPLATE_SUCCESS = "GET_FEEDBACK_TEMPLATE_SUCCESS";
export const GET_FEEDBACK_TEMPLATE_ERROR = "GET_FEEDBACK_TEMPLATE_ERROR";

export const ADD_FEEDBACK_QR_REQUEST = "ADD_FEEDBACK_QR_REQUEST";
export const ADD_FEEDBACK_QR_SUCCESS = "ADD_FEEDBACK_QR_SUCCESS";
export const ADD_FEEDBACK_QR_ERROR = "ADD_FEEDBACK_QR_ERROR";

export const ADD_FEEDBACK_REQUEST = "ADD_FEEDBACK_REQUEST";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_ERROR = "ADD_FEEDBACK_ERROR";

export const RESET_FEEDBACK_TEMPLATE = "RESET_FEEDBACK_TEMPLATE";

export const CLEAR_ALL_FEEDBACK_DATA = "CLEAR_ALL_FEEDBACK_DATA";

export const actions = {
  getFeedbackTemplate: (restaurantId, appSource, onError) => async (dispatch) => {
    dispatch({
      type: GET_FEEDBACK_TEMPLATE_REQUEST,
      payload: {
        source: appSource
      }
    });
    try {
      const response = await getFeedbackTemplate(restaurantId);
      if (response.status === 200) {
        dispatch({
          type: GET_FEEDBACK_TEMPLATE_SUCCESS,
          payload: {
            feedbackTemplate: response?.data?.feedbacktemplate,
            source: appSource
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: GET_FEEDBACK_TEMPLATE_ERROR,
          payload: {
            source: appSource
          }
        });
        onError(response?.data?.message || "Get Feedback Template error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: GET_FEEDBACK_TEMPLATE_ERROR,
        payload: {
          source: appSource
        }
      });
      onError("Get Feedback Template error"); // call the error callback
    }
  },
  addFeedback: (restaurantId, data, onSuccess, onError) => async (dispatch) => {
    dispatch({ type: ADD_FEEDBACK_REQUEST });
    try {
      const response = await addFeedback(
        restaurantId,
        data,
        onSuccess,
        onError
      );
      if (response.status === 200) {
        dispatch({
          type: ADD_FEEDBACK_SUCCESS,
        });
        onSuccess();
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: ADD_FEEDBACK_ERROR,
        });
        onError(response?.data?.message || "Add Feedback error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: ADD_FEEDBACK_ERROR,
      });
      onError("Add Feedback error"); // call the error callback
    }
  },
  addFeedbackQr:
    (restaurantId, data, onSuccess, onError) => async (dispatch) => {
      dispatch({ type: ADD_FEEDBACK_QR_REQUEST });
      try {
        const response = await addFeedbackQr(
          restaurantId,
          data,
          onSuccess,
          onError
        );
        if (response.status === 200) {
          dispatch({
            type: ADD_FEEDBACK_QR_SUCCESS,
          });
          onSuccess();
        } else {
          if (response.status === 401) {
            dispatch({ type: LOGOUT })
          }
          dispatch({
            type: ADD_FEEDBACK_QR_ERROR,
          });
          onError(response?.data?.message || "Add Feedback error"); // call the error callback
        }
      } catch (error) {
        dispatch({
          type: ADD_FEEDBACK_QR_ERROR,
        });
        onError("Add Feedback error"); // call the error callback
      }
    },
  resetFeedbackTemplate: (appSource) => (dispatch) => {
    dispatch({
      type: RESET_FEEDBACK_TEMPLATE,
      payload: {
        source: appSource
      }
    });
  },
  clearAllFeedbackData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_FEEDBACK_DATA
    })
  }
};
