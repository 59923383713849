import { getPopularItems } from '../../config/config';
import { toast } from 'react-toastify';

export const GET_POPULAR_ITEMS_REQUEST = 'GET_POPULAR_ITEMS_REQUEST';
export const GET_POPULAR_ITEMS_SUCCESS = 'GET_POPULAR_ITEMS_SUCCESS';
export const GET_POPULAR_ITEMS_ERROR = 'GET_POPULAR_ITEMS_ERROR';
export const CLEAR_ALL_POPULAR_ITEMS_DATA = 'CLEAR_ALL_POPULAR_ITEMS';

export const actions = {
  getPopularItems: (restaurantId, appSource) => async (dispatch) => {
    dispatch({ type: GET_POPULAR_ITEMS_REQUEST });
    await getPopularItems(restaurantId).then((res) => {
      if (res?.status === 200) {
        // setTimeout(() => {
        dispatch({
          type: GET_POPULAR_ITEMS_SUCCESS,
          payload: {
            popularItems: res?.data?.products,
            appSource: appSource
          },
        });
        // }, 5000);
      } else {
        toast.error(res?.data?.message || 'Get Popular Items error');
        dispatch({
          type: GET_POPULAR_ITEMS_ERROR,
        });
      }
    });
  },
  clearAllPopularItemsData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_POPULAR_ITEMS_DATA
    })
  }
};
