import { signup, login, verify, verifyAfterPhoneUpdate } from "../../config/config";

import { RESET_USER } from './UserActions';
import { SET_FCM_TOKEN } from "./NotificationsActions";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";
export const LOGOUT = "LOGOUT";

export const actions = {
  //SIGNUP
  signup: (data, onSuccess, onError) => async (dispatch) => {
    dispatch({ type: SIGNUP_REQUEST });
    try {
      const response = await signup(data);
      if (response.status === 200) {
        dispatch({ type: SIGNUP_SUCCESS });
        onSuccess(); // call the success callback
      } else {
        dispatch({
          type: SIGNUP_ERROR,
        });
        onError(response?.data || response?.data?.message || "Signup error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: SIGNUP_ERROR,
      });
      onError(error?.response?.data || error?.response?.data?.message || "Signup error"); // call the error callback
    }
  },
  //LOGIN
  login: (phone, onSuccess, onAccountNotVerified, onAccountNotFound, onError) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await login({ phone });
      if (response.status === 200) {
        dispatch({ type: LOGIN_SUCCESS });
        onSuccess(); // call the success callback
      } else {
        dispatch({
          type: LOGIN_ERROR,
        });
        if (response.status === 604) {
          onAccountNotVerified();
        } else if (response.status === 603) {
          onAccountNotFound(); // call the account not found callback
        } else {
          onError(response?.data || response?.data?.message || "Login error"); // call the error callback
        }
      }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
      onError(error?.response?.data?.message || "Login error"); // call the error callback
    }
  },
  //VERIFY
  verify: (phone, token, isUpdatingPhone, onSuccess, onError) => async (dispatch) => {
    dispatch({ type: VERIFY_REQUEST });
    try {
      const response = isUpdatingPhone ? await verifyAfterPhoneUpdate({ phone, token }) : await verify({ phone, token });
      if (response.status === 200) {
        dispatch({
          type: VERIFY_SUCCESS,
          payload: { token: response?.data?.access_token },
        });
        onSuccess(); // call the success callback
      } else {
        dispatch({
          type: VERIFY_ERROR,
        });
        onError(response?.data?.message || "Verification error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: VERIFY_ERROR,
      });
      onError(error?.response?.data?.message || "Verification error"); // call the error callback
    }
  },
  //LOGOUT
  logout: () => (dispatch) => {
    dispatch({ type: LOGOUT });
    dispatch({ type: RESET_USER });
    dispatch({ type: SET_FCM_TOKEN, payload: "" })
  },
};

export default actions;
