import { getTranslation } from '../../config/config';
import { toast } from 'react-toastify';

export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR';

export const actions = {
  getTranslation: () => async (dispatch) => {
    dispatch({ type: GET_TRANSLATION_REQUEST });
    
    try {
      const res = await getTranslation();
      if (res?.status === 200) {
        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: {
            supportedLanguages: res?.data?.supportedLanguages,
            translation: res?.data?.translations[0]?.languages,
          },
        });
      } else {
        const errorMessage = res?.data?.message || 'Get Translation error';
        toast.error(errorMessage);
        dispatch({ type: GET_TRANSLATION_ERROR });
      }
    } catch (error) {
      toast.error('Get Translation error');
      dispatch({ type: GET_TRANSLATION_ERROR });
    }
  },
};
