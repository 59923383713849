import {
    SET_LAST_APP_OPEN_TIME,
    GET_DESIGNS_REQUEST,
    GET_DESIGNS_SUCCESS,
    GET_DESIGNS_ERROR,
    SET_SELECTED_THEME,
    UPDATE_CURRENT_RESTAURANT_THEME_DATA,
    RESET_DESIGNS
} from "../actions/DesignsActions";

const initialState = {
    lastAppOpenTime: null,
    loginDesignSetup: null,
    splashScreen: null,
    designs: [],
    getDesignsLoading: false,
    currentRestaurantDesign: null,
    currentRestaurantModeIconsStyle: "",
    selectedTheme: "light-mode",
    mainDesign: null,
};

const getCurrentRestaurantDesignData = (designs, ruuid, theme) => {
    const currentRestaurantDesignData = designs?.find((design) => {
        return design?.restaurantUuid == ruuid;
    })?.designSetup;
    if (!currentRestaurantDesignData || currentRestaurantDesignData?.length === 0) {
        return null;
    }
    //else
    return (currentRestaurantDesignData?.length === 1 || theme === "light-mode") ? currentRestaurantDesignData[0] : currentRestaurantDesignData[1];
}

const getCurrentRestaurantModeIconsStyle = (currentRestaurantData) => {
    if (!currentRestaurantData) {
        return "FullColor";
    }
    //else
    let currentRestoModeIconsStyleValue;
    if (currentRestaurantData?.iconsStyle === "coloredIcons") {
        currentRestoModeIconsStyleValue = "Colored";
    } else if (currentRestaurantData?.iconsStyle === "linearIcons") {
        currentRestoModeIconsStyleValue = "Linear";
    } else if (currentRestaurantData?.iconsStyle === "fullColorIcons") {
        currentRestoModeIconsStyleValue = "FullColor";
    } else {
        currentRestoModeIconsStyleValue = "Minimalist";
    }
    return currentRestoModeIconsStyleValue;
}

const getMainDesign = (designs) => {
    if (!designs || designs?.length === 0) {
        return null;
    }
    //else
    const mainDesignValue = designs?.find((design) => {
        return design?.designSetup[0]?.isMainDesign
    })
    if (mainDesignValue) {
        return mainDesignValue;
    } else {
        return null;
    }
}

function designs(state = initialState, action) {
    switch (action.type) {
        case SET_LAST_APP_OPEN_TIME:
            return Object.assign({}, state, {
                lastAppOpenTime: action?.payload?.value
            });
        case GET_DESIGNS_REQUEST:
            return Object.assign({}, state, {
                getDesignsLoading: true,
            });
        case GET_DESIGNS_SUCCESS:
            return Object.assign({}, state, {
                getDesignsLoading: false,
                designs: action?.payload?.designs,
                loginDesignSetup: action?.payload?.loginDesignSetup,
                splashScreen: action?.payload?.splashScreen,
                mainDesign: getMainDesign(action?.payload?.designs)
            });
        case GET_DESIGNS_ERROR:
            return Object.assign({}, state, {
                getDesignsLoading: false,
            });
        case SET_SELECTED_THEME:
            return Object.assign({}, state, {
                selectedTheme: action?.payload?.value,
            });
        case UPDATE_CURRENT_RESTAURANT_THEME_DATA:
            const currentRestoDesign = getCurrentRestaurantDesignData(state?.designs, action?.payload?.ruuid, action?.payload?.mode);
            return Object.assign({}, state, {
                currentRestaurantDesign: currentRestoDesign,
                currentRestaurantModeIconsStyle: action?.payload?.ruuid ? getCurrentRestaurantModeIconsStyle(currentRestoDesign) : ""
            })
        case RESET_DESIGNS:
            return Object.assign({}, state, {
                loginDesignSetup: null,
                splashScreen: null,
                designs: [],
                getDesignsLoading: false
            });
        default:
            return state;
    }
}

export default designs;
